.preview_img{
    width: 300px;
    height: 190px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    object-fit: cover;
}
.preview_img .main_img{
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* box-shadow: inset 0 0 10px 0 #222222; */
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.preview_img img{
    width: 100%;
    height: 100%;
    display: block;
}
.imgHidden{
    display: none;
}
.imgUploadCss{
    display: block;
    /* width: 150px; */
    /* background: #4358df; */
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Inter', sans-serif;
    padding: 4px ;
    margin-top: 16px;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

}
img[alt="Not Image Selected"]{
   color: #4453c7;
}

